<template>
  <div class="auth-wrapper">
    <div class="left-part">
      <div class="container-fluid">
        <div class="row align-items-center left-part">
          <div class="col-lg-6 col-md-6 order-lg-first order-md-first">
            <div class="details">
              <div class="logo">
                <img
                  :src="require('@/assets/images/logo/muslim-do-logo.png')"
                  class="logo-img"
                  alt="logo"
                >
              </div>
              <div class="great">
                <div class="text-center">
                  <p>Login with your data you entered <br> during your registiration</p>
                </div>
              </div>
            </div>
            <div class="form-wrapper d-flex justify-content-center">
              <form @submit.enter.prevent="">
                <div class="form-group has-icon">
                  <label
                    for="email"
                    class="email-label"
                  >Email</label>
                  <feather-icon
                    icon="MailIcon"
                    size="12"
                    class="form-control-feedback"
                  />
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="email"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </div>
                <div class="form-group has-icon">
                  <label
                    for="password"
                    class="pass-label"
                  >Password</label>
                  <feather-icon
                    icon="LockIcon"
                    size="12"
                    class="form-control-feedback"
                  />
                  <input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control"
                    placeholder="Password"
                  >
                  <feather-icon
                    :icon="icon"
                    size="12"
                    class="form-control-eye"
                    @click="switchVisibility"
                  />
                </div>
                <div class="form-check d-inline">
                  <input
                    id="remember-check"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label"
                    for="remember-check"
                  >Remember Me</label>
                </div>
                <div class="etc-login-form float-right">
                  <b-link
                    :to="{name:'auth-forgot-password'}"
                    class="etc-login-form-label"
                  >Forget Password?</b-link>
                </div>
                <div>
                  <button
                    class="btn btn-primary mt-2 button"
                    @click="login()"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 align-items-center d-flex justify-content-center right-part">
            <div class="login-details">
              <div class="top">
                <p class="text-center display-4 text-light">
                  LOGIN
                </p>
                <p class="text-center sign-up">
                  haven't account yet? <a href="#">Sign up</a>
                </p>
              </div>
              <div class="slick">
                <VueSlickCarousel v-bind="settings">
                  <div><img
                    :src="require('@/assets/images/slides/first-slide.png')"
                    alt="logo"
                    width="100%"
                  ><p class="pt-2 text-light">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque, tempore.
                  </p></div>
                  <div><img
                    :src="require('@/assets/images/slides/seconde-slide.png')"
                    alt="logo"
                    width="100%"
                  ><p class="pt-2 text-light">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque, tempore.
                  </p></div>
                  <div><img
                    :src="require('@/assets/images/slides/third-slide.png')"
                    alt="logo"
                    width="100%"
                  ><p class="pt-2 text-light">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque, tempore.
                  </p></div>
                </VueSlickCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

/* eslint-disable global-require */
import { BFormInput, BLink } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required, email } from '@validations'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'

// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

Vue.use(VueSweetalert2)

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VueSlickCarousel, ValidationProvider, ValidationObserver, BFormInput, BLink,
  },
  data() {
    return {
      email: '',
      password: '',
      passwordFieldType: 'password',
      icon: 'EyeIcon',
      settings: {
        dots: true,
        arrows: false,
        dotsClass: 'slick-dots custom-dot-class',
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      required,
    }
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      this.icon = this.icon === 'EyeIcon' ? 'EyeOffIcon' : 'EyeIcon'
    },
    login() {
      axios.post('https://gateway.muslimdo.com/api/users/login', { email: this.email, password: this.password })
        .then(res => {
          if (res.data.status !== 200) {
            this.$swal(
              'Inavalid',
              res.data.message,
              'warning',
            )
          } else if (res.data.status === 200) {
            localStorage.setItem('access_token', res.data.data.access_token)
            localStorage.setItem('user', res.data.data)
            this.$router.push('/dashboard')
          // eslint-disable-next-line no-empty
          } else {}
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          // eslint-disable-next-line no-undef
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss">
.left-part {
    background-color: white;
}

.right-part{
  height: 400px;
  background-color: #24BDC6;
}

.logo-img {
    width: 30%;
}

.logo {
    text-align-last: center;
    margin-bottom: 2rem;
}

.great {
    color: black;
}

form {
    width: 50%;
}

.has-icon .form-control {
    padding-left: 3rem;
    padding-right: 3rem;
}
.has-icon .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 1.5rem;
    margin-top: 5px;
    margin-left: 5px;
    height: 2rem;
    line-height: 5rem;
    text-align: center;
    pointer-events: none;
}

.has-icon .form-control-eye {
    display: inline;
    width: 2rem;
    height: 2rem;
    float: right;
    position: relative;
    bottom: 2.3rem;
    right: 1rem;
    cursor: pointer;
}

input#email, input#password {
    border: 1px solid #24BDC6;
}

.email-label, .pass-label{
    position: relative;
    background-color: #f8f8f8;
    top: 13px;
    left: 8%;
    font-size: 12px;
    color: #21BBC4;
}

label.form-check-label {
    margin-left: 29px;
    font-size: 14px;
}

input#remember-check {
    height: 18px;
    width: 18px;
    margin: 0;
}

.button{
  width: 100%;
  background-color: #21BBC4 !important;
  border-color: #21BBC4 !important;
}

.right-part{
  height: 100vh;
}

.sign-up a {
    color: black;
    font-weight: bold;
    text-decoration: underline;
}

.slick {
    text-align: center;
    text-align: -webkit-center;
}

.login-details {
    width: 50%;
}

.sign-up {
    font-size: 16px;
    color: black !important;
    font-weight: 500;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 100px;
  line-height: 0;
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  content: '-';
  opacity: 0.25;
  color: white;
  border-radius: 50px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: rgb(255, 255, 255);
}
</style>
